import request from "@/utils/request";
import Export from "@/utils/export";
// 卡券新增
export function addCard(data) {
  return request("post", "/apm/virtual/package/create", data);
}
// 卡券列表
export function cardList(data) {
  return request("post", "/apm/virtual/package/page", data);
}
// 导出
export function goExportCsv(data,name) {
    return Export("post", "/apm/virtual/package/export", data,name,false,true);
  }
